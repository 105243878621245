<div class="popup-confirm">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <h4 mat-dialog-title>{{ data.message }}</h4>
  <div class="support-areas">
    <form [formGroup]="groupsForm">
      <table class="support-table">
        <tbody>
          <tr *ngFor="let area of data.supportAreas; let i = index">
            <td>
              <input type="checkbox" [formControlName]="area.name" />
            </td>
            <td>
              <b>{{ area.name }}</b>
            </td>
            <td>
              {{ area.title }}
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancelar</button>
    <button mat-button class="btn-crear" [disabled]="groupsForm.errors?.notChecked"
      (click)="onCreateClick()">{{data.btnLabel}}</button>
  </div>
</div>